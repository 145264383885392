
import * as React from 'react';
import { State } from './state';
import { UserContext } from './context';
import { User } from '@/common/models';
import { reducer } from './reducer';

export const initialState: State = {
    currentUser: {
        _id: '',
        name: 'Guest User',
        lastname: '',
        isLogged: false,
        ci: '',
        email: '',
        isAdmin: false,
        organizations: [],
        adminOf: []
    },
    logingIn: false,
    loginError: null,

    users: new Array<User>(),
    loadingUsers: false,
    loadingUsersError: null
};

export const UserContextProvider: React.FunctionComponent = ({ children }) => (
    <UserContext.Provider value={ React.useReducer(reducer, initialState) }>
        { children }
    </UserContext.Provider>
);