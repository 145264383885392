
import { Organization } from '@/common/models';
import * as React from 'react';
import { OrganizationContext } from './context';
import { reducer } from './reducer';
import { State } from './state';

export const initialState: State = {
    organizations: new Array<Organization>(),
    loadingOrganizations: false,
    loadingOrganizationsError: null
};

export const OrganizationContextProvider: React.FunctionComponent = ({ children }) => (
    <OrganizationContext.Provider value={ React.useReducer(reducer, initialState) }>
        { children }
    </OrganizationContext.Provider>
);