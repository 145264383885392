
import { api } from '@/api';
import { Organization } from '@/common/models';

export class OrganizationService {

    public static async all(): Promise<Array<Organization>> {
        return (await api().get<{organizations: Array<Organization>}>('/api/organizations')).data.organizations;
    }

    public static async get(id: string): Promise<Organization> {
        return (await api().get<{organization: Organization}>(`/api/organizations/${id}`)).data.organization;
    }

    public static async delete(id: string): Promise<void> {
        return await api().delete(`/api/organizations/${id}`);
    }

    public static async create(organization: Organization): Promise<Organization> {
        return (await api().post<{organization: Organization}>('/api/organizations', organization)).data.organization;
    }

    public static async update(organization: Partial<Organization>): Promise<Organization> {
        return (await api().put<{organization: Organization}>(`/api/organizations/${organization._id}`, organization)).data.organization;
    }

}
