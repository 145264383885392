
import { api } from '@/api';
import { User } from '@/common/models';
import { LoginRequest } from './models/login';
import { LoginResponse } from './models/login/login-response';

export class UserService {

    public static async login(data: LoginRequest): Promise<LoginResponse> {
        return  (await api().post<LoginResponse>('/api/authenticate', data)).data;
    }

    public static async check(): Promise<User> {
        if ( ! localStorage.getItem('token')) {
            throw new Error('No token');
        }
        return (await api().get<{user: User}>('/api/authenticate')).data.user;
    }

    public static async all(): Promise<Array<User>> {
        return (await api().get<{users: Array<User>}>('/api/users')).data.users;
    }

    public static async get(id: string): Promise<User> {
        return (await api().get<{user: User}>(`/api/users/${id}`)).data.user;
    }

    public static async create(user: User): Promise<User> {
        return (await api().post<{user: User}>('/api/users', user)).data.user;
    }

    public static async update(user: Partial<User>): Promise<User> {
        return (await api().put<{user: User}>(`/api/users/${user._id}`, user)).data.user;
    }

    public static async delete(id: string): Promise<void> {
        return await api().delete(`/api/users/${id}`);
    }

}
