import { Action } from './action';
import { State } from "./state";
import { initialState } from './provider';

export const reducer = (state: State, action: Action): State => {
    console.log('Called', action);
    if (action.type === 'AUTH::start') {
        return { ...state, logingIn: true, loginError: '' };
    }
    if (action.type === 'AUTH::end') {
        return { ...state, logingIn: false};
    }
    if (action.type === 'AUTH::success') {
        if (action.payload.token) {
            localStorage.setItem('token', action.payload.token);
        }
        return { ...state, logingIn: false, currentUser: { ...action.payload.user, isLogged: true }};
    }
    if (action.type === 'AUTH::clear') {
        return { ...state, logingIn: false, loginError: '' };
    }
    if (action.type === 'AUTH::error') {
        return { ...state, logingIn: false, loginError: action.payload.error };
    }
    if (action.type === 'AUTH::set_token') {
        localStorage.setItem('token', action.payload.token);
    }
    if (action.type === 'AUTH::logout') {
        localStorage.removeItem('token');
        return { ...state, currentUser: { ...initialState.currentUser} };
    }
    if (action.type === 'USERS::load_all_start') {
        return { ...state, loadingUsers: true };
    }
    if (action.type === 'USERS::load_all_success') {
        return { ...state, loadingUsers: false, users: action.payload.users };
    }
    if (action.type === 'USERS::load_all_end') {
        return { ...state, loadingUsers: false };
    }
    if (action.type === 'USERS::load_all_error') {
        return { ...state, loadingUsers: false, loadingUsersError: action.payload.error };
    }
    if (action.type === 'USERS::remove') {
        return { ...state, users: state.users.filter(({ _id }) => _id !== action.payload._id) };
    }
    if (action.type === 'USERS::update') {
        return { ...state, users: state.users.map((user) => {
            if (user._id !== action.payload.user._id) {
                return user;
            }
            return {...user, ...action.payload.user};
        }) };
    }
    if (action.type === 'USERS::add') {
        return { ...state, users: state.users.concat(action.payload.user) };
    }
    if (action.type === 'USERS::upsert') {
        return {
            ...state,
            users: state.users
                .filter(({ _id }) => _id !== action.payload.user._id)
                .concat(action.payload.user)
        };
    }

    return state;
}