import { Action } from './action';
import { State } from "./state";

export const reducer = (state: State, action: Action): State => {
    console.log('Called', action);
    if (action.type === 'ORGANIZATIONS::load_all_start') {
        return { ...state, loadingOrganizations: true };
    }
    if (action.type === 'ORGANIZATIONS::load_all_success') {
        return { ...state, loadingOrganizations: false, organizations: action.payload.organizations };
    }
    if (action.type === 'ORGANIZATIONS::load_all_end') {
        return { ...state, loadingOrganizations: false };
    }
    if (action.type === 'ORGANIZATIONS::load_all_error') {
        return { ...state, loadingOrganizations: false, loadingOrganizationsError: action.payload.error };
    }
    if (action.type === 'ORGANIZATIONS::remove') {
        return { ...state, organizations: state.organizations.filter(({ _id }) => _id !== action.payload._id) };
    }
    if (action.type === 'ORGANIZATIONS::update') {
        return { ...state, organizations: state.organizations.map((organization) => {
            if (organization._id !== action.payload.organization._id) {
                return organization;
            }
            return {...organization, ...action.payload.organization};
        }) };
    }
    if (action.type === 'ORGANIZATIONS::add') {
        return { ...state, organizations: state.organizations.concat(action.payload.organization) };
    }
    if (action.type === 'ORGANIZATIONS::upsert') {
        return {
            ...state,
            organizations: state.organizations
                .filter(({ _id }) => _id !== action.payload.organization._id)
                .concat(action.payload.organization)
        };
    }

    return state;
}