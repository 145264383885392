import GlobalPreloader from '@/assets/img/global-preloader.gif';
import UserContext, { UserContextProvider } from '@/common/contexts/user';
import { UserService } from '@/common/services';
import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { OrganizationContextProvider } from './common/contexts/organization';

const LoginPage = React.lazy(() => import('./pages/login'));
const RegisterPage = React.lazy(() => import('./pages/register'));
const DashboardPage = React.lazy(() => import('./pages/dashboard'));

const Topbar = styled.div`
    height: 5px;
    width: 100%;
    background: #0B0B1E;
`;

const Loader: React.FunctionComponent = React.memo(() => {
    const Img = styled.img`
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    `;
    return (
        <Img src={ GlobalPreloader } alt='preload' />
    );
});

export const App: React.FunctionComponent = () => {

    return (
        <Router>
            <Switch>
                <UserContextProvider>
                    <OrganizationContextProvider>
                        <Inside />
                    </OrganizationContextProvider>
                </UserContextProvider>
            </Switch>
        </Router>
    );
};

const Inside: React.FunctionComponent = React.memo(() => {
    const [ tryingFirstAuthCheck, settryingFirstAuthCheck ] = React.useState(true);
    const context = React.useContext(UserContext);

    if ( ! context) {
        return null;
    }

    const [ userStore, dispatch ] = context;

    React.useEffect(() => {
        dispatch({ type: 'AUTH::start' });
        UserService.check()
        .then(user => {
            dispatch({ type: 'AUTH::success', payload: { user } });
        })
        .catch(error => {
            dispatch({ type: 'AUTH::error', payload: { error } });
        })
        .finally(() => {
            settryingFirstAuthCheck(false);
        });
    }, [dispatch]);

    if (tryingFirstAuthCheck) {
        return <Loader />;
    }

    return (
        <React.Suspense fallback={ ( <Loader /> ) }>
            { ! userStore.currentUser.isLogged ? (
                <>
                    <Topbar />
                    <Route exact path='/' component={ LoginPage } />
                    <Route path='/register' component={ RegisterPage } />
                    <Route component={ LoginPage } />
                </>
            ) : (
                <Route path='/' component={ DashboardPage } />
            ) }
        </React.Suspense>
    );
});
